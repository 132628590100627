/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/gatsby-styles/gatsby-temp.css";
import { GtmHelper } from 'kh-common-components';

export function onClientEntry() {
    //console.log(process.env.GTM_ID);

    if (window.requireGtm === undefined || window.requireGtm !== false) {
        
        GtmHelper.setup(process.env.GTM_ID);

        if (window.environmentName !== undefined && window.environmentName !== null) {
            const cdsMap = new Map();
            cdsMap.set('cd-environment', window.environmentName);
            GtmHelper.sendCustomDimensions(cdsMap);
        }        
    }

    return false;
}

export function shouldUpdateScroll() {
    return false;
}